import React, { useState, useEffect } from 'react';
import { Settings, HelpCircle, Home, Database, LogOut, X, Menu } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSession, useDescope, getSessionToken } from '@descope/react-sdk';
import { toast } from 'react-toastify';
import apiClient from '../utils/apiClient';
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from 'react-router-dom';

// Import your existing component files
import DashboardHome from './dashboard/dashboardhome';
import SettingsForm from './dashboard/settingsform';
import SyncForm from './dashboard/syncform';

const stripePromise = loadStripe(
  "pk_live_51QL9nuBySApRicVUmFsIkWlf1K97iYbRWAIY49E0096xYc0TFj7UYtvuzscKG1rC61eSohsOs2h35wVbgeDhMxlt00T0h2wUZE"
  // "pk_test_51QL9nuBySApRicVUHHjJpQpg45hijjIZoPDJ6vaHwvnPXpSoluxtgUqmn28Y1Xufi0r6aKpJommjkn8k8e6lmtcW00oFkQBKtx"
);

const DataSyncDashboard = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const location = useLocation();
  const [params, setParams] = useState({});

  const [loading, setLoading] = useState(true);
  const [trial, setTrial] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();
  const { logout } = useDescope();
  const sessionToken = getSessionToken();

  useEffect(() => {
    // Function to extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const paramsObj = {};
    
    queryParams.forEach((value, key) => {
      paramsObj[key] = value;
    });

    setParams(paramsObj);
    if(paramsObj.tab == 'sync') {
      setActiveTab('sync')
    }
  }, [location]);

  // Fetch dashboard data
  useEffect(() => {
    const proPayment = async () => {
      const check = await apiClient.post(
        '/payment-check',
        {
          email: localStorage.getItem('databackfill-useremail')
        }
      );
      if(check.paid=='paid') {
        toast.error('You already paid!');
        setLoading(false)
        return;
      }
      const response = await apiClient.post(
        `/api/create-checkout-session`,
        {
          email: localStorage.getItem('databackfill-useremail')
        }
      );
      console.log("🚀 ~ handleCheckout ~ data:", response);

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: response.sessionId });
      setLoading(false);
    }
    console.log( 'storage', localStorage.getItem('pricing'))
    if(localStorage.getItem('pricing') == 'professional') {
      localStorage.setItem('pricing', '')
      proPayment();
    }
    
    

    const fetchData = async () => {
      try {
        console.log('ok');
        const check = await apiClient.post(
          '/payment-check',
          {
            email: localStorage.getItem('databackfill-useremail')
          }
        );
        console.log('payment', check);
        if(check.paid!='paid') {
          setLoading(false);
        } else {
          setIsPaid(true);
        }

        const data = await apiClient.get('/api/data');
        console.log('data', data);
        setDashboardData(data);
        setLoading(false);
        if(!data || data.credential == 'none') {
          setActiveTab('settings')
        }

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    
    console.log('userEfect')
    const createdTime = localStorage.getItem('createdTime');
    const timestamp = createdTime
    const currentDate = new Date(); // Get the current date
    const currentTimestamp = currentDate.getTime() / 1000; // Convert current time to UNIX timestamp (seconds)

    // Calculate the difference in seconds
    const differenceInSeconds = currentTimestamp - timestamp;

    // Convert the difference to days
    const differenceInDays = Math.floor(differenceInSeconds / (60 * 60 * 24)); 
    console.log('DifferceL: ', differenceInDays);
    setTrial(differenceInDays<7 ? 7-differenceInDays : 0);
  }, []);

  // Check authentication
  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate('/auth');
  //   }
  // }, [isAuthenticated, navigate]);

  // Handle logout
  const handleLogout = () => {
    logout();
    toast.info('You have been logged out.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    navigate('/');
  };

  // Handle when sync is completed to refresh dashboard data
  const handleSyncComplete = async () => {
    try {
      const data = await apiClient.get('/api/data');
      setDashboardData(data);
      setActiveTab('home');
    } catch (error) {
      console.error('Error refreshing dashboard data:', error);
    }
  };

  const handleRedirectPricing = () => {
    navigate('/pricing');
  }


  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="flex items-center justify-between px-4 md:px-6 py-4">
          <div className="flex items-center space-x-4">
            {/* Mobile menu button */}
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden p-2 rounded-lg text-gray-600 hover:bg-gray-100"
            >
              {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            <div className="text-blue-600 font-bold text-xl">DataBackfill</div>
          </div>
          <div className="flex items-center space-x-4">
              {isPaid || loading? "" :
                trial> 0? 
                <div className='text-red-400'>
                  {trial} Days trial left
                  
                    <button
                      onClick={handleRedirectPricing}
                      className="ml-4 py-2 px-4 rounded-lg font-medium text-sm bg-blue-600 hover:bg-blue-700 text-white"
                    >
                      Upgrade
                    </button>
                </div>
                :
                <div className='text-red-400'>
                  Trial Ended
                </div>
              }
            <button 
              onClick={() => navigate('/documentation')} 
              className="text-gray-600 hover:text-blue-600 hidden sm:block"
              title="Documentation"
            >
              <HelpCircle size={20} />
            </button>
            <button
              onClick={handleLogout}
              className="text-gray-600 hover:text-blue-600 flex items-center"
            >
              <LogOut size={20} />
              <span className="ml-1 hidden sm:inline">Logout</span>
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex min-h-[calc(100vh-64px)]">
        {/* Sidebar - Mobile Drawer / Desktop Sidebar */}
        <div
          className={`
            fixed md:relative inset-0 z-30 md:z-auto
            transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0
            transition-transform duration-300 ease-in-out
            bg-white w-64 border-r border-gray-200
            ${isSidebarOpen ? 'block' : 'hidden'} md:block
          `}
        >
          {/* Overlay for mobile */}
          {isSidebarOpen && (
            <div 
              className="fixed inset-0 bg-black/20 md:hidden" 
              onClick={() => setIsSidebarOpen(false)}
            />
          )}

          {/* Sidebar Content */}
          <div className="relative z-10 h-full bg-white p-4">
            <div className="space-y-2">
              <button
                onClick={() => {
                  setActiveTab('home');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'home'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Home size={20} />
                <span>Dashboard</span>
              </button>
              <button
                onClick={() => {
                  setActiveTab('sync');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'sync'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Database size={20} />
                <span>Data Sync</span>
              </button>
              <button
                onClick={() => {
                  setActiveTab('settings');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'settings'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Settings size={20} />
                <span>Google Cloud</span>
              </button>
            </div>
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1 p-4 md:p-6 overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">
              Error loading dashboard: {error}
            </div>
          ) : (
            <>
              {activeTab === 'home' && <DashboardHome data={dashboardData} paid={isPaid} loading = {loading}/>}
              {activeTab === 'sync' && (
                <SyncForm onSyncComplete={handleSyncComplete} paid={isPaid} />
              )}
              {activeTab === 'settings' && <SettingsForm  paid={isPaid}/>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataSyncDashboard;